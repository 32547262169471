import React from "react";
import styles from "./Directions.module.css"; // Assume you have corresponding CSS
import venueImage from "../images/venue.webp"; // Adjust path as necessary

const Directions = () => {
  const handleGetDirections = () => {
    window.open(
      "https://www.google.com/maps/dir//Hamburg+Brewing+Company,+6553+Boston+State+Rd,+Hamburg,+NY+14075/data=!4m7!4m6!1m1!4e2!1m2!1m1!1s0x89d304851786a385:0x90c2ccc95259748c!3e0?sa=X&ved=1t:3747&ictx=111"
    );
  };

  return (
    <div className={styles.directions}>
      <h2 className={styles.title}>Venue Location - Hamburg Brewing Company</h2>
      <div className={styles.content}>
        <img
          src={venueImage}
          alt="Hamburg Brewing Company"
          className={styles.image}
        />
        <div className={styles.info}>
          <p>
            <strong>Address:</strong> 6553 Boston State Rd, Hamburg, NY 14075
          </p>
          <p>
            Join us at the climate-controlled Events Center, nestled in a
            picturesque garden by a serene pond. The venue offers ample parking
            and is handicap accessible, ensuring a comfortable and enjoyable
            experience for all our guests.
          </p>
        </div>
      </div>
      <div className={styles.buttons}>
        <button className={styles.button} onClick={handleGetDirections}>
          Get Directions
        </button>
      </div>
    </div>
  );
};

export default Directions;
