import React from "react";
import "normalize.css";
import "./global.css";
import Hero from "./components/Hero";
import Directions from "./components/Directions";
import Header from "./components/Header";
import Footer from "./components/Footer";
import About from "./components/About";
import Menu from "./components/Menu";

function App() {
  return (
    <div className="App">
      <Header />
      <Hero />
      <div id="about">
        <About />
      </div>
      <div id="menu">
        <Menu />
      </div>
      <div id="directions">
        <Directions />
      </div>
      <Footer />
    </div>
  );
}

export default App;
