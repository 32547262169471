import React from "react";
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import image1 from "../images/mom-dancing.jpg";
import image2 from "../images/mom-and-mike-wedding.jpg";

import image5 from "../images/mom-and-mike.jpg";
import image6 from "../images/wedding-entrance.jpg";
import styles from "./Hero.module.css";

const images = [image1, image2, image5, image6];

const Hero = () => {
  const handleButtonClick = () => {
    window.open("https://forms.gle/zmnAyPvdqLBYponS7", "_blank");
  };

  return (
    <div className={styles.hero}>
      <Fade duration={3000} transitionDuration={1000} infinite={true}>
        {images.map((image, index) => (
          <div key={index} className={styles.slide}>
            <div
              className={styles.backgroundImage}
              style={{ backgroundImage: `url(${image})` }}
            ></div>
          </div>
        ))}
      </Fade>
      <div className={styles.content}>
        <h1 className={styles.title}>
          Sharon Montour's 60th Birthday Surprise Party
        </h1>
        <p className={styles.info}>Saturday, June 15, 2024 @ 5:00 PM</p>
        <p className={styles.info}>Hamburg Brewing Company</p>
        <p className={styles.infoDirections}>
          6553 Boston State Rd, Hamburg, NY 14075
        </p>
        <button className={styles.rsvpButton} onClick={handleButtonClick}>
          RSVP
        </button>
      </div>
    </div>
  );
};

export default Hero;
