import React from "react";
import styles from "./Header.module.css";
import logo from "../images/logo.png";

function Header() {
  return (
    <div className={styles.header}>
      <div className={styles.title}>
        <img src={logo} alt="Logo" />
      </div>
      <div className={styles.links}>
        <a href="#about">About</a>
        <a href="#menu">Menu</a>
        <a href="#directions">Directions</a>
      </div>
      <div className={styles.rsvp}>
        <button
          onClick={() =>
            window.open("https://forms.gle/zmnAyPvdqLBYponS7", "_blank")
          }
        >
          RSVP
        </button>
      </div>
    </div>
  );
}

export default Header;
