import React from "react";
import styles from "./Menu.module.css"; // Updated CSS for card layout and hover effects

const Menu = () => {
  return (
    <div className={styles.menu}>
      <h2 className={styles.menuTitle}>Open Bar and Food Options</h2>
      <p>
        Celebrate Sharon Montour's 60th in style with an open bar boasting
        Hamburg Brewing's finest beers, crafted cocktails, and choice wines.
        Feast all night on a lavish buffet brimming with tasty bites and hearty
        entrees. It's not just a party; it's an endless feast and toast to fun!
      </p>
      <div className={styles.cardsContainer}>
        <div className={`${styles.card} ${styles.openBar}`}>
          <h3>Open Bar Selections</h3>
          <p>
            Enjoy a variety of premium spirits, selected wines, beers, and more.
          </p>
        </div>
        <div className={`${styles.card} ${styles.appetizers}`}>
          <h3>Gourmet Bites & Appetizers</h3>
          <p>
            Indulge in an assortment of artisan cheeses, fresh fruits, and
            savory dips.
          </p>
        </div>
        <div className={`${styles.card} ${styles.entrees}`}>
          <h3>Main Fare & Entrees</h3>
          <p>
            Feast on delicious options including Beef on Weck, seafood delights,
            and more.
          </p>
        </div>
        <div className={`${styles.card} ${styles.desserts}`}>
          <h3>Decadent Desserts</h3>
          <p>
            Conclude your journey with ice cream sundaes, cheesecake, and more.
          </p>
        </div>
      </div>
      <p className={styles.note}>
        Food and drinks will be available throughout the event for your
        enjoyment.
      </p>
    </div>
  );
};

export default Menu;
