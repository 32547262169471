import React from "react";
import styles from "./About.module.css";
import sharonImage from "../images/mom-and-dougie.jpg";

const About = () => {
  const handleClick = () => {
    window.open("https://forms.gle/zmnAyPvdqLBYponS7", "_blank");
  };

  return (
    <div className={styles.about}>
      <div className={styles.container}>
        <h1 className={styles.heading}>
          Celebrating Sharon Montour's 60th Birthday!
        </h1>
        <div className={styles.contentWrapper}>
          <img
            src={sharonImage}
            alt="Sharon Montour"
            className={styles.image}
          />
          <div className={styles.textContent}>
            <p>
              We're gathering to celebrate a very special occasion — Sharon
              Montour's 60th birthday — and you're invited! Mark your calendars
              for <strong>Saturday, June 15, 2024</strong>, and join us for an
              unforgettable evening of family, friends, and good times!
            </p>

            <p>
              <h2>The Surprise</h2>
              <strong>Please make sure to arrive by 5:00 PM!</strong> Sharon is
              set to arrive at 5:30 PM, providing us ample opportunity to get
              everything in place for the big surprise upon her arrival!
              <h2>Food & Drinks</h2>
              This celebration will be open bar, and includes a variety of
              Hamburg Brewing's craft beers, wines, cocktails, and soft drinks.
              A selection of delicious foods will be served throughout the
              evening,
              <h2>Entertainment & Activities</h2>
              Enjoy live music, delightful drinks, a photo booth, and a stroll
              down memory lane with a video montage of Sharon’s most precious
              moments. Don’t miss out on sharing your own stories!
              <h2>Dress Code</h2>
              Smart casual. Come dressed comfortably but ready to celebrate.
              <h2>What to Bring</h2>
              Your presence is the greatest gift of all. If you wish to bring
              something, let's fill a Memory Box for Sharon. Write a note, share
              a photo, or bring a small keepsake that reminds you of a special
              time shared with her.
              
            </p>

            <button className={styles.rsvpButton} onClick={handleClick}>
              Join us!
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
