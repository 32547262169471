import React from "react";
import styles from "./Footer.module.css"; // Add this import for your CSS module

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const copyright = `© ${currentYear} Jim Montour`;

  return (
    <footer className={styles.footer}>
      <p className={styles.copyright}>{copyright}</p>
    </footer>
  );
};

export default Footer;
